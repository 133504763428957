export default {
  methods: {
    addThousandsComma(num) {
      let parts = String(num).split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
    handleDecimal(price, currency) {
      if (currency !== "USD") return Math.round(price);
      if (!String(price).includes(".")) return price;
      return (Math.round(price * 100) / 100).toFixed(2);
    },
    addCurrencySymbol(price, currency) {
      if (isNaN(Number(price))) return 0;
      const currencySymbol = {
        TWD: "$",
        USD: "$",
        CNY: "¥"
      };
      const formattedPrice = this.addThousandsComma(
        this.handleDecimal(Number(price), currency)
      );
      return `${currencySymbol[currency]}${formattedPrice} ${currency}`;
    }
  }
};
