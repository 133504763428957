<template>
  <span class="income">
    <span>{{ `+${number}` }}</span>
  </span>
</template>

<script>
export default {
  props: ["number"]
};
</script>

<style lang="scss" scoped>
.income {
  color: #FFFFFF;
  background-color: var(--themeColor);
  padding: 2px 6px;
  border-radius: 4px;
}
</style>
