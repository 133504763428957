<template>
  <div>
    <el-radio-group v-model="reasons" size="medium">
      <el-radio-button label="ALL">All</el-radio-button>
      <el-radio-button label="STORED">Recharge with money</el-radio-button>
    </el-radio-group>
    <el-table :data="incomes">
      <el-table-column label="Name">
        <template slot-scope="scope">
          <router-link
            :to="{ name: 'ProfileForAdmin', params: { id: scope.row.user_id } }"
          >
            {{ scope.row.user.name }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="Email" width="260px">
        <template slot-scope="scope">
          {{ scope.row.user.email }}
        </template>
      </el-table-column>
      <el-table-column label="Item">
        <template slot-scope="scope">
          {{ scope.row.description ? scope.row.description[lang] : "" }}
        </template>
      </el-table-column>
      <el-table-column label="T-Coins">
        <template slot-scope="scope">
          <IncomeNumber :number="scope.row.number" />
        </template>
      </el-table-column>
      <el-table-column label="Date" width="120px">
        <template slot-scope="scope">
          {{ instant.formatGoogleTime(scope.row.transacted_at) }}
        </template>
      </el-table-column>
      <el-table-column label="Location">
        <template slot-scope="scope">
          {{
            scope.row.transaction_log_location
              ? `${scope.row.transaction_log_location.ip} (${scope.row.transaction_log_location.country})`
              : "-"
          }}
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="$store.getters['payment/incomePageSize']"
        :current-page="$store.getters['payment/incomePage']"
        @current-change="fetchIncomes"
        :total="$store.getters['payment/totalIncomeNumber']"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { instant } from "@ivy-way/material";
import IncomeNumber from "@/components/payment/IncomeNumber";

export default {
  data() {
    return {
      reasons: "ALL"
    };
  },
  components: {
    IncomeNumber
  },
  computed: {
    incomes() {
      return this.$store.getters["payment/incomes"];
    },
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    instant() {
      return instant;
    }
  },
  watch: {
    reasons() {
      this.fetchIncomes();
    }
  },
  created() {
    this.fetchIncomes();
  },
  methods: {
    fetchIncomes(page = 1) {
      this.$store.dispatch("payment/fetchIncomes", {
        page,
        reasons: this.reasons === "STORED" ? ["STORED"] : null
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination {
  margin: 20px auto;

  .el-pagination {
    margin: 0px auto;
  }
}
</style>
