<template>
  <div>
    <el-table :data="expenses">
      <el-table-column label="Name">
        <template slot-scope="scope">
          <router-link
            :to="{ name: 'ProfileForAdmin', params: { id: scope.row.user_id } }"
          >
            {{ scope.row.user.name }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="Email" width="260px">
        <template slot-scope="scope">
          {{ scope.row.user.email }}
        </template>
      </el-table-column>
      <el-table-column label="Item">
        <template slot-scope="scope">
          {{ scope.row.description ? scope.row.description[lang] : "" }}
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <ExpenseNumber :number="scope.row.number" />
        </template>
      </el-table-column>
      <el-table-column label="Date" width="120px">
        <template slot-scope="scope">
          {{ instant.formatGoogleTime(scope.row.transacted_at) }}
        </template>
      </el-table-column>
      <el-table-column label="Location">
        <template slot-scope="scope">
          {{
            scope.row.transaction_log_location
              ? `${scope.row.transaction_log_location.ip} (${scope.row.transaction_log_location.country})`
              : "-"
          }}
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="$store.getters['payment/expensePageSize']"
        :current-page="$store.getters['payment/expensePage']"
        @current-change="fetchExpenses"
        :total="$store.getters['payment/totalExpenseNumber']"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { instant } from "@ivy-way/material";
import ExpenseNumber from "@/components/payment/ExpenseNumber";
import moneyMixin from "@/mixins/money";

export default {
  mixins: [moneyMixin],
  components: {
    ExpenseNumber
  },
  computed: {
    expenses() {
      return this.$store.getters["payment/expenses"];
    },
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    instant() {
      return instant;
    }
  },
  created() {
    this.fetchExpenses();
  },
  methods: {
    fetchExpenses(page = 1) {
      this.$store.dispatch("payment/fetchExpenses", { page });
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination {
  margin: 20px auto;

  .el-pagination {
    margin: 0px auto;
  }
}
</style>
