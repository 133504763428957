<template>
  <span class="expense">
    <span>{{ `-${number}` }}</span>
  </span>
</template>

<script>
export default {
  props: ["number"]
};
</script>

<style lang="scss" scoped>
.expense {
  color: #FFFFFF;
  background-color: #ff5252;
  padding: 2px 6px;
  border-radius: 4px;
}
</style>
