import { render, staticRenderFns } from "./IncomeNumber.vue?vue&type=template&id=6056edbd&scoped=true&"
import script from "./IncomeNumber.vue?vue&type=script&lang=js&"
export * from "./IncomeNumber.vue?vue&type=script&lang=js&"
import style0 from "./IncomeNumber.vue?vue&type=style&index=0&id=6056edbd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6056edbd",
  null
  
)

export default component.exports