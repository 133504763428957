<template>
  <div>
    <el-table :data="transactions">
      <el-table-column label="Name">
        <template slot-scope="scope">
          <router-link
            :to="{ name: 'ProfileForAdmin', params: { id: scope.row.user_id } }"
          >
            {{ scope.row.user.name }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="Email" width="260px">
        <template slot-scope="scope">
          {{ scope.row.user.email }}
        </template>
      </el-table-column>
      <el-table-column label="Unit">
        <template slot-scope="scope">
          <span v-if="scope.row.unit === 'point'">T-Coins</span>
          <span v-else-if="scope.row.unit === 'unlock_print_number'">
            解鎖成績單使用次數
          </span>
        </template>
      </el-table-column>
      <el-table-column width="80px">
        <template slot-scope="scope">
          <IncomeNumber
            v-if="scope.row.category === 'income'"
            :number="scope.row.number"
          />
          <ExpenseNumber
            v-else-if="scope.row.category === 'expense'"
            :number="scope.row.number"
          />
        </template>
      </el-table-column>
      <el-table-column label="Item">
        <template slot-scope="scope">
          {{ scope.row.description ? scope.row.description[lang] : "" }}
        </template>
      </el-table-column>
      <el-table-column label="Date" width="120px">
        <template slot-scope="scope">
          {{ instant.formatGoogleTime(scope.row.transacted_at) }}
        </template>
      </el-table-column>
      <el-table-column label="Location">
        <template slot-scope="scope">
          {{
            scope.row.transaction_log_location
              ? `${scope.row.transaction_log_location.ip} (${scope.row.transaction_log_location.country})`
              : "-"
          }}
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="$store.getters['payment/transactionPageSize']"
        :current-page="$store.getters['payment/transactionPage']"
        @current-change="fetchTransactions"
        :total="$store.getters['payment/totalTransactionNumber']"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { instant } from "@ivy-way/material";
import ExpenseNumber from "@/components/payment/ExpenseNumber";
import IncomeNumber from "@/components/payment/IncomeNumber";
import moneyMixin from "@/mixins/money";

export default {
  mixins: [moneyMixin],
  components: {
    ExpenseNumber,
    IncomeNumber
  },
  computed: {
    transactions() {
      return this.$store.getters["payment/transactions"];
    },
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    instant() {
      return instant;
    }
  },
  created() {
    this.fetchTransactions();
  },
  methods: {
    fetchTransactions(page = 1) {
      this.$store.dispatch("payment/fetchTransactions", { page });
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination {
  margin: 20px auto;

  .el-pagination {
    margin: 0px auto;
  }
}
</style>
